import React from 'react'
import { Tab } from 'semantic-ui-react';

import Layout from '../components/layout'
import ContactForm from '../components/ContactForm/ContactForm';
import SupportForm from '../components/SupportForm/SupportForm';
import Container from '../components/Container/Container';
import Hero from '../components/Hero/Hero';

const TabItems = [
  {
    menuItem: { key: 'contact', content: 'Contact', icon: 'mail' },
    pane: (<Tab.Pane key="contact"><ContactForm /></Tab.Pane>),
  },
  {
    menuItem: { key: 'support', content: 'Support', icon: 'ambulance' },
    pane: (<Tab.Pane key="suppport"><SupportForm /></Tab.Pane>),
  }
]

const Contact = () => (
  <Layout>
    <Hero interior>

    </Hero>
    <Container>
      <section>
        <h1>Contact Us</h1>
        <Tab
          renderActiveOnly={false}
          panes={TabItems}
        />
      </section>
    </Container>
  </Layout>
)

export default Contact;
