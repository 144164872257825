import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { Form, Modal, Button, Tab, Menu, Icon } from 'semantic-ui-react';
import qs from 'qs';
import { toast } from 'react-toastify';

interface ISupportFormState {
  firstName: string;
  lastName: string;
  email: string;
  open: boolean;
  help: string;
  'form-name': string;
}

class SupportForm extends Component<{}, ISupportFormState> {

  constructor(props: any) {
    super(props);

    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      'form-name': 'support',
      open: false,
      help: '',
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, { name, value }: { name: any, value: string }): void {
    this.setState({
      [name]: value
    } as Pick<ISupportFormState, keyof ISupportFormState>);
  }

  handleSubmit(event: React.FormEvent<HTMLFormElement>): void {
    event.preventDefault();

    toast('🏃‍♂️ Submitting your request.', {
      autoClose: 2000
    });

    axios
      .post('/', qs.stringify(this.state))
      .then(() => {

        toast.success('🙌 Your form has been submitted successfully!');

        this.setState({
          firstName: '',
          lastName: '',
          email: '',
          help: '',
        })
      })
      .catch(e => {
        console.log(e)
        toast.error('🤦‍♂️ There was an error with your submission. Try again or contact Drew directly at drewhuett@gmail.com', {
          autoClose: false
        })
      })
  }

  render() {
    const { firstName, lastName, email, help } = this.state;

    return (
      <Fragment>
        <h3>Support</h3>
        <Form
          onSubmit={this.handleSubmit}
          name='support'
          method='post'
          data-netlify='true'
          data-netlify-honeypot='location'
        >
          <div className='u-hidden'>
            <Form.Input type='hidden' name='form-name' value='support' />
            <Form.Input type='hidden' name='location' />
          </div>

          <Form.Group widths='equal'>
            <Form.Input
              value={firstName}
              placeholder='First Name'
              label='First Name'
              type='text'
              name='firstName'
              onChange={this.handleChange}
              required
            />

            <Form.Input
              alue={lastName}
              placeholder='Last Name'
              label='Last Name'
              type='text'
              name='lastName'
              onChange={this.handleChange}
              required
            />
          </Form.Group>

          <Form.Input
            value={email}
            placeholder='Email'
            label='Email'
            type='email'
            name='email'
            onChange={this.handleChange}
            required
          />

          <Form.TextArea
            value={help}
            placeholder="Leave a brief description about the issue you're having and we'll get back to you as soon as possible"
            label='How can we help?'
            name='help'
            onChange={this.handleChange}
            required
          />

          <Form.Button positive type='submit' content='Submit' />
        </Form>
      </Fragment>
    )
  }
}

export default SupportForm;
